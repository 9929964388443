@import '../../main/variables/variables.scss';

.accounts-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 62px;

        .account-form {
            display: grid;
            height: fit-content;
            gap: 12px;
            max-width: 500px;

            button {
                width: 100%;
            }

            .login-button {
                width: 50%;
            }
        }

        .promo {
            position: relative;
            height: 540px;
            width: 540px;

            .promo-img {
                img {
                    height: 540px;
                    width: 540px;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.297);
                z-index: 2;
                border-radius: 10px;
            }

            .promo-content {
                position: absolute;
                z-index: 3;
                top: 70%;
                width: 100%;
                color: white;

                display: grid;
                justify-items: center;
                height: fit-content;

                h1 {
                    color: white;
                }

            }
        }
    }
}

.forgot-password-page {
    .forgot-password-form {
        height: 70vh;
        display: grid;
        align-content: center;
        gap: 24px;
        max-width: 540px;
        margin: auto;
    }
}


@media screen and (max-width: 860px) {
    .accounts-page {
        height: unset;

        .container {
            flex-direction: column-reverse;
            padding: 48px 24px;

            .account-form {
                .login-button {
                    width: 100%;
                }

                span {
                    text-align: center;
                }
            }

            .promo {
                display: none;

                .promo-img {
                    img {
                        height: 250px;
                    }
                }
            }
        }
    }

}