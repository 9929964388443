.accounts-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accounts-page .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 62px;
}
.accounts-page .container .account-form {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
  max-width: 500px;
}
.accounts-page .container .account-form button {
  width: 100%;
}
.accounts-page .container .account-form .login-button {
  width: 50%;
}
.accounts-page .container .promo {
  position: relative;
  height: 540px;
  width: 540px;
}
.accounts-page .container .promo .promo-img img {
  height: 540px;
  width: 540px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.accounts-page .container .promo .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.297);
  z-index: 2;
  border-radius: 10px;
}
.accounts-page .container .promo .promo-content {
  position: absolute;
  z-index: 3;
  top: 70%;
  width: 100%;
  color: white;
  display: grid;
  justify-items: center;
  height: -moz-fit-content;
  height: fit-content;
}
.accounts-page .container .promo .promo-content h1 {
  color: white;
}

.forgot-password-page .forgot-password-form {
  height: 70vh;
  display: grid;
  align-content: center;
  gap: 24px;
  max-width: 540px;
  margin: auto;
}

@media screen and (max-width: 860px) {
  .accounts-page {
    height: unset;
  }
  .accounts-page .container {
    flex-direction: column-reverse;
    padding: 48px 24px;
  }
  .accounts-page .container .account-form .login-button {
    width: 100%;
  }
  .accounts-page .container .account-form span {
    text-align: center;
  }
  .accounts-page .container .promo {
    display: none;
  }
  .accounts-page .container .promo .promo-img img {
    height: 250px;
  }
}/*# sourceMappingURL=accounts.css.map */