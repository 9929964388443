.pricing-page {
  padding: 48px 0;
}
.pricing-page .container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  justify-items: center;
  gap: 88px;
}
.pricing-page .container .pricing-header {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  max-width: 600px;
  gap: 24px;
}
.pricing-page .container .pricing-plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.pricing-page .container .pricing-plans .plan {
  padding: 24px;
  background-color: whitesmoke;
  border-radius: 6px;
  display: grid;
  gap: 12px;
  transition: all ease-in-out 0.4s;
}
.pricing-page .container .pricing-plans .plan .plan-header {
  display: flex;
  align-items: center;
  gap: 12px;
}
.pricing-page .container .pricing-plans .plan .plan-header .icon {
  background-color: #F6FFFA;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.pricing-page .container .pricing-plans .plan .plan-header .icon i {
  font-size: 22px;
}
.pricing-page .container .pricing-plans .plan .pricing {
  display: flex;
  align-items: baseline;
  gap: 6px;
}
.pricing-page .container .pricing-plans .plan .details {
  display: grid;
  gap: 12px;
}
.pricing-page .container .pricing-plans .plan .details .detail {
  display: flex;
  align-items: center;
  gap: 6px;
}
.pricing-page .container .pricing-plans .plan .details .detail .icon {
  background-color: gray;
  height: 8px;
  width: 8px;
  border-radius: 100px;
}
.pricing-page .container .pricing-plans .plan .action-button {
  width: 100%;
  background-color: transparent;
  border: 1px solid #146454;
}
.pricing-page .container .pricing-plans .plan:hover {
  background-color: #146454;
  color: white;
}
.pricing-page .container .pricing-plans .plan:hover h3,
.pricing-page .container .pricing-plans .plan:hover h2 {
  color: white;
}
.pricing-page .container .pricing-plans .plan:hover .plan-header .icon {
  background-color: #D4CCA4;
}
.pricing-page .container .pricing-plans .plan:hover .plan-header .icon i {
  color: #146454;
}
.pricing-page .container .pricing-plans .plan:hover .details .detail .icon {
  background-color: white;
}
.pricing-page .container .pricing-plans .plan:hover .action-button {
  color: white;
  border-color: white;
}
.pricing-page .container .pricing-plans .plan:hover .action-button:hover {
  background-color: #D4CCA4;
  color: #146454;
}
.pricing-page .container .pricing-plans .promo {
  background-color: #c8f3ea;
}
.pricing-page .container .pricing-plans .promo .action-button {
  background-color: #146454;
  color: white;
}

.mobile-payment {
  display: flex;
  align-items: center;
  gap: 12px;
}

@media screen and (max-width: 840px) {
  .pricing-page .container .pricing-plans {
    width: 100%;
    flex-direction: column;
  }
  .pricing-page .container .pricing-plans .plan {
    width: 100%;
  }
}
@media screen and (max-width: 840px) {
  .pricing-page .container .pricing-plans {
    justify-content: start;
  }
}/*# sourceMappingURL=pricing.css.map */