@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;300;400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  background-color: #fbfff6;
  transition: ease-in-out 0.3s;
}

img {
  max-width: 100%;
  border-radius: 12px;
}

.container {
  padding: 20px;
  max-width: 100%;
  margin: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

button,
.btn {
  padding: 14px 25px;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  transition: all ease-in-out 0.3s;
  border: 1px solid transparent;
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
}
button .span,
.btn .span {
  margin-left: 6px;
}

.btn-primary {
  background-color: #146454;
  color: white;
}
.btn-primary:hover {
  background-color: transparent;
  color: #146454;
  border-radius: 0;
  border: 1px solid #146454;
}

.shadow {
  box-shadow: 4px 4px 90px 2px rgba(20, 100, 84, 0.45);
}

.open-pop-up-link {
  color: #146454;
  text-decoration: underline;
  cursor: pointer;
}

.link-inline-link {
  text-decoration: underline;
}

header {
  padding: 20px 0;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fbfff6;
  z-index: 100;
}
header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header nav .branding {
  display: flex;
  align-items: center;
  gap: 12px;
}
header nav .branding .site-icon {
  width: 38px;
}
header nav .branding .site-name {
  color: #146454;
}
header nav .menu {
  overflow-y: clip;
  display: grid;
  justify-items: center;
  gap: 24px;
  position: absolute;
  right: 0;
  left: 0;
  background-color: #ecf4e4;
  top: 90px;
  bottom: 0;
  height: -moz-fit-content;
  height: fit-content;
  padding: 53px 24px;
  visibility: hidden;
}
header nav .menu .menu-list {
  width: 100%;
  display: grid;
  justify-items: center;
  text-align: center;
  gap: 24px;
}
header nav .menu .menu-list .menu-item {
  font-weight: bold;
}
header nav .menu .social-icons {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  gap: 32px;
}
header nav .menu .social-icons i {
  color: #146454;
}
header nav .open-menu {
  border-radius: 0;
}

section {
  overflow-x: clip;
}

.hero {
  padding-top: 150px;
}
.hero .container {
  display: grid;
  justify-items: center;
  gap: 24px;
}
.hero .container .site-title {
  font-size: 50px;
}
.hero .container .promo-images {
  position: relative;
  height: 450px;
  width: 100%;
}
.hero .container .promo-images .promo-small-image {
  position: absolute;
  right: -60px;
  width: 180px;
  transform: rotate(27deg);
}
.hero .container .promo-images .promo-big-image {
  position: absolute;
  bottom: 40%;
}

.chat {
  position: relative;
}
.chat .container {
  text-align: center;
  display: grid;
  gap: 24px;
  justify-items: center;
}
.chat .container .chat-images {
  width: 100%;
  height: 320px;
}
.chat .container .chat-images .char-image-potrait {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 150px;
  transform: rotate(-27deg);
}
.chat .container .chat-images .char-image-desktop {
  position: absolute;
  right: -150px;
  width: 100%;
  max-width: 500px;
  bottom: 0;
}

.product-discovery {
  padding: 50px 0;
}
.product-discovery .container {
  display: grid;
  justify-items: center;
  text-align: center;
  gap: 24px;
}
.product-discovery .container .product-discovery-images {
  margin-top: 20px;
  height: 400px;
  width: 100%;
  position: relative;
}
.product-discovery .container .product-discovery-images .landscape {
  display: none;
}
.product-discovery .container .product-discovery-images .potrait {
  position: relative;
  z-index: 0;
}
.product-discovery .container .product-discovery-images .search {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
}

.fee-structure {
  padding-top: 50px;
  height: 630px;
  position: relative;
}
.fee-structure .container .fee-image-potrait {
  position: absolute;
  bottom: 0;
  left: -50px;
  transform: rotate(-27deg);
  width: 180px;
  z-index: 1;
}
.fee-structure .container .col-right {
  display: grid;
  justify-items: center;
  text-align: center;
  gap: 24px;
}
.fee-structure .container .col-right .fee-image-lanscape {
  position: absolute;
  bottom: 62px;
  right: -50px;
  z-index: 0;
}

.subscription {
  padding: 50px 0;
}
.subscription .container {
  display: grid;
  justify-items: center;
  text-align: center;
  gap: 24px;
}
.subscription .container .sub-image {
  margin-top: 50px;
}

.empower-vendors {
  position: relative;
}
.empower-vendors .container .col-left {
  display: grid;
  gap: 24px;
}
.empower-vendors .container .col-right {
  height: 400px;
  width: 100%;
}
.empower-vendors .container .col-right .image-vendor {
  position: absolute;
  right: -50%;
  transform: rotate(-50deg);
}

.buyer-experience {
  position: relative;
}
.buyer-experience .container {
  display: flex;
  flex-direction: column-reverse;
}
.buyer-experience .container .col-left {
  width: 100%;
  height: 300px;
}
.buyer-experience .container .col-left .image-buyer {
  position: absolute;
  bottom: 0;
  right: -70%;
  transform: scale(1.4) rotate(50deg);
}
.buyer-experience .container .col-right {
  display: grid;
  gap: 24px;
}

.mobile-app .container {
  display: grid;
}
.mobile-app .container .col-left {
  display: grid;
  gap: 24px;
}
.mobile-app .container .col-right {
  width: 100%;
  height: 360px;
  position: relative;
  display: grid;
  justify-items: center;
  margin-top: 50px;
}
.mobile-app .container .col-right .help {
  position: absolute;
  left: 0;
  bottom: 30px;
  height: 250px;
  z-index: 0;
}
.mobile-app .container .col-right .chat {
  position: absolute;
  right: 0;
  height: 250px;
  bottom: 30px;
  z-index: 0;
}
.mobile-app .container .col-right .intro {
  width: 164px;
  position: relative;
  z-index: 1;
}

.faqs {
  padding: 50px 0;
}
.faqs .container {
  display: grid;
  gap: 50px;
}
.faqs .container .questions-answers {
  display: grid;
  gap: 24px;
}
.faqs .container .questions-answers .summary {
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  background-color: #ecf4e4;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  position: relative;
}
.faqs .container .questions-answers .summary:before {
  content: "+";
  position: absolute;
  left: 10px;
  transition: transform 0.3s ease;
}
.faqs .container .questions-answers .summary.open {
  background-color: white;
}
.faqs .container .questions-answers .summary.open:before {
  content: "-";
  transform: rotate(45deg);
}
.faqs .container .questions-answers p {
  display: none;
  padding: 12px;
  background-color: #ecf4e4;
  border-top: none;
  border-radius: 0 0 5px 5px;
}
.faqs .container .questions-answers details[open] p {
  display: block;
  animation: fade-in 0.5s ease;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 760px) {
  .container {
    padding: 0 50px;
  }
  header {
    padding: 20px 0;
  }
  header nav .menu {
    visibility: visible;
    position: unset;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 0;
  }
  header nav .menu .menu-list {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  header nav .menu .social-icons {
    gap: 12px;
  }
  header nav .open-menu {
    display: none;
  }
  .hero .container .promo-images {
    display: grid;
    grid-template-columns: 80% 20%;
    gap: 24px;
    margin-top: 50px;
  }
  .hero .container .promo-images .promo-small-image,
  .hero .container .promo-images .promo-big-image {
    position: unset;
    transform: none;
    height: 100%;
    height: auto;
  }
  .chat .container .chat-images {
    height: 450px;
  }
  .chat .container .chat-images .char-image-potrait {
    width: 250px;
  }
  .chat .container .chat-images .char-image-desktop {
    width: 100%;
    right: -200px;
  }
  .product-discovery {
    padding-top: 100px;
  }
  .product-discovery .container {
    display: grid;
    justify-items: start;
  }
  .product-discovery .container .title,
  .product-discovery .container p {
    width: 60%;
    text-align: start;
  }
  .product-discovery .container .product-discovery-images {
    height: auto;
    display: grid;
    grid-template-columns: 30% 14.5% 50%;
    gap: 24px;
    align-items: end;
  }
  .product-discovery .container .product-discovery-images .landscape {
    display: block;
  }
  .product-discovery .container .product-discovery-images .search {
    position: unset;
  }
  .product-discovery .container .product-discovery-images img {
    width: 100%;
    height: auto;
  }
  .fee-structure {
    height: auto;
  }
  .fee-structure .container {
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: end;
    gap: 24px;
  }
  .fee-structure .container .fee-image-potrait {
    position: unset;
    transform: none;
    height: auto;
  }
  .fee-structure .container .col-right {
    justify-items: start;
    text-align: start;
  }
  .fee-structure .container .col-right .fee-image-lanscape {
    position: unset;
    height: auto;
  }
  .empower-vendors .container .col-right {
    margin-top: 50px;
    height: auto;
  }
  .empower-vendors .container .col-right .image-vendor {
    position: unset;
    transform: none;
  }
  .buyer-experience {
    padding: 100px 0;
  }
  .buyer-experience .container .col-left {
    height: auto;
    margin-top: 50px;
  }
  .buyer-experience .container .col-left .image-buyer {
    position: unset;
    transform: none;
  }
  .mobile-app {
    height: 900px;
  }
  .mobile-app .container {
    position: relative;
  }
  .mobile-app .container .col-right {
    position: absolute;
    right: -250px;
    height: 750px;
    width: 700px;
  }
  .mobile-app .container .col-right .help {
    height: 100%;
    max-height: 600px;
  }
  .mobile-app .container .col-right .chat {
    height: 100%;
    max-height: 600px;
  }
  .mobile-app .container .col-right .intro {
    width: 100%;
    max-width: 360px;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    max-width: 1200px;
  }
  .hero {
    padding-bottom: 100px;
  }
  .hero .container .promo-images {
    height: auto;
  }
  .hero .container .promo-images .promo-big-image,
  .hero .container .promo-images .promo-small-image {
    height: 500px;
    width: auto;
  }
  .chat {
    position: unset;
  }
  .chat .container {
    position: relative;
  }
  .chat .container .chat-images .char-image-potrait {
    left: 150px;
    top: 150px;
    bottom: unset;
    width: 200px;
  }
  .chat .container .chat-images .char-image-desktop {
    right: 50px;
    max-width: 600px;
  }
  .subscription {
    padding: 100px 0;
  }
  .empower-vendors .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }
  .buyer-experience .container {
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    gap: 24px;
  }
  .buyer-experience .container .col-left {
    width: 40%;
    height: 300px;
    position: relative;
  }
  .buyer-experience .container .col-left .image-buyer {
    position: absolute;
    max-width: 570px;
    height: auto;
    left: 0;
    right: 0;
    top: 50px;
  }
  .buyer-experience .container .col-right {
    width: 60%;
    text-align: end;
    display: grid;
    justify-items: end;
  }
  .buyer-experience .container .col-right p {
    width: 50%;
  }
  .mobile-app {
    height: auto;
    padding-bottom: 60px;
  }
  .mobile-app .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }
  .mobile-app .container .col-right {
    height: auto;
    width: 459px;
    position: relative;
    right: unset;
  }
  .mobile-app .container .col-right .intro {
    width: 220px;
  }
  .mobile-app .container .col-right .help,
  .mobile-app .container .col-right .chat {
    height: 361px;
  }
}
.pop-up {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.433);
  z-index: 101;
  display: none;
  align-items: center;
  justify-content: center;
}
.pop-up .pop-up-content {
  position: relative;
  max-width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #ecf4e4;
  padding: 48px;
  display: grid;
  gap: 24px;
  border-radius: 12px;
}
.pop-up .pop-up-content .pop-up-actions {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 24px;
  top: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ecf4e4;
  cursor: pointer;
}
.pop-up .pop-up-content .notification-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
}
.pop-up .pop-up-content .notification-form input {
  padding: 12px 20px;
  width: 100%;
}
.pop-up .pop-up-content .notification-form .submit-button {
  display: flex;
  align-items: center;
  gap: 6px;
}
.pop-up .pop-up-content .notification-form .submit-button input {
  padding: 0;
  background-color: transparent;
  color: inherit;
  outline: none;
  border: none;
}
.pop-up .pop-up-content .notification-form .submit-section {
  display: flex;
  align-items: center;
  gap: 12px;
}
.pop-up .pop-up-content .notification-form .submit-section .submit-button {
  cursor: pointer;
}
.pop-up .pop-up-content .notification-form .submit-section #loading-spinner {
  display: none;
  font-size: 28px;
  color: #146454;
  animation: spin 2s linear infinite;
}
.pop-up .pop-up-content .notification-form .submit-section .submited {
  color: #146454;
  display: none;
}
.pop-up .pop-up-content .notification-form .submit-section .failed {
  color: red;
  display: none;
}
.pop-up .pop-up-content .notification-form .half {
  width: 48%;
}

@media screen and (max-width: 540px) {
  .pop-up {
    padding: 24px;
  }
  .pop-up .pop-up-content .notification-form {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .pop-up .pop-up-content .notification-form .half {
    min-width: 100%;
  }
  .pop-up .pop-up-content .notification-form .submit-section {
    display: grid;
    gap: 12px;
  }
}
@keyframes spin {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
.footer {
  background-color: #146454;
  padding: 58px 50px;
  color: white;
}
.footer .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}
.footer .container .row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.footer .container .row .col {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.footer .container .row .col h2 {
  color: white;
}
.footer .container .row .col ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
}
.footer .container .row .col ul li a {
  color: white;
  text-decoration: none;
}
.footer .container .copyrights {
  display: flex;
  width: 100%;
  padding-top: 24px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #fff;
}
.footer .container .copyrights .socialMedia {
  display: flex;
  align-items: flex-start;
  gap: 17px;
}
.footer .container .copyrights .socialMedia i {
  color: white;
}

@media screen and (max-width: 780px) {
  .footer {
    padding: 20px 12px;
  }
  .footer .container .row {
    display: grid;
    grid-template-columns: 48% 48%;
  }
  .footer .container .row .col {
    width: 100%;
    gap: 12px;
    padding-bottom: 24px;
  }
  .footer .container .copyrights {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
}
@media screen and (max-width: 540px) {
  .footer {
    padding: 20px 12px;
  }
  .footer .container .row {
    display: flex;
    flex-direction: column;
  }
  .footer .container .row .col {
    display: flex;
    gap: 12px;
    padding-bottom: 24px;
  }
  .footer .container .copyrights {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
}
.menu-open {
  height: 100vh;
  visibility: visible;
  overflow-y: clip;
  display: grid;
  justify-items: center;
  gap: 24px;
  position: absolute;
  right: 0;
  left: 0;
  background-color: #ecf4e4;
  top: 90px;
  bottom: 0;
  padding: 53px 24px;
}/*# sourceMappingURL=marketing.css.map */