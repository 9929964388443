@import '../../main//variables/variables.scss';

.pricing-page {
    padding: 48px 0;

    .container {
        display: grid;
        height: fit-content;
        justify-items: center;
        gap: 88px;

        .pricing-header {
            display: grid;
            height: fit-content;
            text-align: center;
            max-width: 600px;
            gap: 24px;
        }

        .pricing-plans {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 24px;

            .plan {
                padding: 24px;
                background-color: whitesmoke;
                border-radius: $border-radius;
                display: grid;
                gap: 12px;
                transition: all ease-in-out 0.4s;

                .plan-header {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    .icon {
                        background-color: #F6FFFA;
                        width: 42px;
                        height: 42px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: $border-radius;


                        i {
                            font-size: 22px;
                        }
                    }
                }

                .pricing {
                    display: flex;
                    align-items: baseline;
                    gap: 6px;
                }

                .details {
                    display: grid;
                    gap: 12px;

                    .detail {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        .icon {
                            background-color: gray;
                            height: 8px;
                            width: 8px;
                            border-radius: 100px;
                        }
                    }
                }

                .action-button {
                    width: 100%;
                    background-color: transparent;
                    border: 1px solid $primary-color;
                }

                &:hover {
                    background-color: $primary-color;
                    color: white;

                    h3,
                    h2 {
                        color: white;
                    }

                    .plan-header {
                        .icon {
                            background-color: $accent-color;

                            i {
                                color: $primary-color;
                            }
                        }
                    }

                    .details {
                        .detail {
                            .icon {
                                background-color: white;
                            }
                        }
                    }

                    .action-button {
                        color: white;
                        border-color: white;

                        &:hover {
                            background-color: $accent-color;
                            color: $primary-color;
                        }
                    }
                }
            }

            .promo {
                background-color: #c8f3ea;

                .action-button {
                    background-color: $primary-color;
                    color: white;
                }
            }

        }
    }
}

.mobile-payment {
    display: flex;
    align-items: center;
    gap: 12px;
}

@media screen and (max-width: 840px) {
    .pricing-page {
        .container {

            .pricing-plans {
                width: 100%;
                flex-direction: column;

                .plan {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .pricing-page {
        .container {
            .pricing-plans {
                justify-content: start;
            }
        }
    }
}