@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;300;400;700&display=swap");
//colors

$CPrimary: #146454;
$CSecondary: #3c8464;
$CAccent: #d4cca4;
$CShade: #ecf4e4;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  background-color: #fbfff6;
  transition: ease-in-out 0.3s;
}

img {
  max-width: 100%;
  border-radius: 12px;
}

.container {
  padding: 20px;
  max-width: 100%;
  margin: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

button,
.btn {
  padding: 14px 25px;
  border-radius: 10px;
  width: fit-content;
  transition: all ease-in-out 0.3s;
  border: 1px solid transparent;
  cursor: pointer;
  height: fit-content;

  .span {
    margin-left: 6px;
  }
}

.btn-primary {
  background-color: $CPrimary;
  color: white;

  &:hover {
    background-color: transparent;
    color: $CPrimary;
    border-radius: 0;
    border: 1px solid $CPrimary;
  }
}

.shadow {
  box-shadow: 4px 4px 90px 2px rgba(20, 100, 84, 0.45);
}


.open-pop-up-link {
  color: $CPrimary;
  text-decoration: underline;
  cursor: pointer;
}

.link-inline-link {
  text-decoration: underline;
}

header {
  padding: 20px 0;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fbfff6;
  z-index: 100;

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .branding {
      display: flex;
      align-items: center;
      gap: 12px;

      .site-icon {
        width: 38px;
      }

      .site-name {
        color: $CPrimary;
      }
    }

    .menu {
      overflow-y: clip;
      display: grid;
      justify-items: center;
      gap: 24px;
      position: absolute;
      right: 0;
      left: 0;
      background-color: $CShade;
      top: 90px;
      bottom: 0;
      height: fit-content;
      padding: 53px 24px;
      visibility: hidden;

      .menu-list {
        width: 100%;
        display: grid;
        justify-items: center;
        text-align: center;
        gap: 24px;

        .menu-item {
          font-weight: bold;
        }
      }

      .social-icons {
        width: fit-content;
        display: flex;
        gap: 32px;

        i {
          color: $CPrimary;
        }
      }
    }

    .open-menu {
      border-radius: 0;
    }
  }
}

//hero
section {
  overflow-x: clip;
}

.hero {
  padding-top: 150px;

  .container {
    display: grid;
    justify-items: center;
    gap: 24px;

    .site-title {
      font-size: 50px;
    }

    .promo-images {
      position: relative;
      height: 450px;
      width: 100%;

      .promo-small-image {
        position: absolute;
        right: -60px;
        width: 180px;
        transform: rotate(27deg);
      }

      .promo-big-image {
        position: absolute;
        bottom: 40%;
      }
    }
  }
}

//chat
.chat {
  position: relative;

  .container {
    text-align: center;
    display: grid;
    gap: 24px;
    justify-items: center;

    .chat-images {
      width: 100%;
      height: 320px;

      .char-image-potrait {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 150px;
        transform: rotate(-27deg);
      }

      .char-image-desktop {
        position: absolute;
        right: -150px;
        width: 100%;
        max-width: 500px;
        bottom: 0;
      }
    }
  }
}

//product-discovery

.product-discovery {
  padding: 50px 0;

  .container {
    display: grid;
    justify-items: center;
    text-align: center;
    gap: 24px;

    .product-discovery-images {
      margin-top: 20px;
      height: 400px;
      width: 100%;
      position: relative;

      .landscape {
        display: none;
      }

      .potrait {
        position: relative;
        z-index: 0;
      }

      .search {
        z-index: 2;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -50px;
      }
    }
  }
}

//fee structure
.fee-structure {
  padding-top: 50px;
  height: 630px;
  position: relative;

  .container {
    .fee-image-potrait {
      position: absolute;
      bottom: 0;
      left: -50px;
      transform: rotate(-27deg);
      width: 180px;
      z-index: 1;
    }

    .col-right {
      display: grid;
      justify-items: center;
      text-align: center;
      gap: 24px;

      .fee-image-lanscape {
        position: absolute;
        bottom: 62px;
        right: -50px;
        z-index: 0;
      }
    }
  }
}

// subscription

.subscription {
  padding: 50px 0;

  .container {
    display: grid;
    justify-items: center;
    text-align: center;
    gap: 24px;

    .sub-image {
      margin-top: 50px;
    }
  }
}

//empower vendors

.empower-vendors {
  position: relative;

  .container {
    .col-left {
      display: grid;
      gap: 24px;
    }

    .col-right {
      height: 400px;
      width: 100%;

      .image-vendor {
        position: absolute;
        right: -50%;
        transform: rotate(-50deg);
      }
    }
  }
}

// buyer experience

.buyer-experience {
  position: relative;

  .container {
    display: flex;
    flex-direction: column-reverse;

    .col-left {
      width: 100%;
      height: 300px;

      .image-buyer {
        position: absolute;
        bottom: 0;
        right: -70%;
        transform: scale(1.4) rotate(50deg);
      }
    }

    .col-right {
      display: grid;
      gap: 24px;
    }
  }
}

// mobile app

.mobile-app {
  .container {
    display: grid;

    .col-left {
      display: grid;
      gap: 24px;
    }

    .col-right {
      width: 100%;
      height: 360px;
      position: relative;
      display: grid;
      justify-items: center;
      margin-top: 50px;

      .help {
        position: absolute;
        left: 0;
        bottom: 30px;
        height: 250px;
        z-index: 0;
      }

      .chat {
        position: absolute;
        right: 0;
        height: 250px;
        bottom: 30px;
        z-index: 0;
      }

      .intro {
        width: 164px;
        position: relative;
        z-index: 1;
      }
    }
  }
}

// Frequently Asked Questions

.faqs {
  padding: 50px 0;

  .container {
    display: grid;
    gap: 50px;

    .questions-answers {
      display: grid;
      gap: 24px;

      .summary {
        cursor: pointer;
        font-weight: bold;
        padding: 10px;
        background-color: $CShade;
        border-radius: 5px;
        transition: background-color 0.3s ease;
        position: relative;

        &:before {
          content: "+";
          position: absolute;
          left: 10px;
          transition: transform 0.3s ease;
        }

        &.open {
          background-color: white;

          &:before {
            content: "-";
            transform: rotate(45deg);
          }
        }
      }

      p {
        display: none;
        padding: 12px;
        background-color: $CShade;
        border-top: none;
        border-radius: 0 0 5px 5px;
      }

      details[open] p {
        display: block;
        animation: fade-in 0.5s ease;
      }

      @keyframes fade-in {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (min-width: 760px) {
  .container {
    padding: 0 50px;
  }

  //header
  header {
    padding: 20px 0;

    nav {
      .menu {
        visibility: visible;
        position: unset;
        background-color: transparent;
        display: flex;
        align-items: center;
        padding: 0;

        .menu-list {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        .social-icons {
          gap: 12px;
        }
      }

      .open-menu {
        display: none;
      }
    }
  }

  //end header

  //hero
  .hero {
    .container {
      .promo-images {
        display: grid;
        grid-template-columns: 80% 20%;
        gap: 24px;
        margin-top: 50px;

        .promo-small-image,
        .promo-big-image {
          position: unset;
          transform: none;
          height: 100%;
          height: auto;
        }
      }
    }
  }

  //end of the hero

  //chat
  .chat {
    .container {
      .chat-images {
        height: 450px;

        .char-image-potrait {
          width: 250px;
        }

        .char-image-desktop {
          width: 100%;
          right: -200px;
        }
      }
    }
  }

  // end chat

  //product discovery
  .product-discovery {
    padding-top: 100px;

    .container {
      display: grid;
      justify-items: start;

      .title,
      p {
        width: 60%;
        text-align: start;
      }

      .product-discovery-images {
        height: auto;
        display: grid;
        grid-template-columns: 30% 14.5% 50%;
        gap: 24px;
        align-items: end;

        .landscape {
          display: block;
        }

        .search {
          position: unset;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  //end of product discovery

  //fee structure
  .fee-structure {
    height: auto;

    .container {
      display: grid;
      grid-template-columns: 30% 70%;
      align-items: end;
      gap: 24px;

      .fee-image-potrait {
        position: unset;
        transform: none;
        height: auto;
      }

      .col-right {
        justify-items: start;
        text-align: start;

        .fee-image-lanscape {
          position: unset;
          height: auto;
        }
      }
    }
  }

  //end of fee structure

  //no styles for subscription on this breakpoint

  //empower vendors
  .empower-vendors {
    .container {
      .col-right {
        margin-top: 50px;
        height: auto;

        .image-vendor {
          position: unset;
          transform: none;
        }
      }
    }
  }

  //end of empower vendors

  .buyer-experience {
    padding: 100px 0;

    .container {
      .col-left {
        height: auto;
        margin-top: 50px;

        .image-buyer {
          position: unset;
          transform: none;
        }
      }
    }
  }

  // mobile app
  .mobile-app {
    height: 900px;

    .container {
      position: relative;

      .col-right {
        position: absolute;
        right: -250px;
        height: 750px;
        width: 700px;

        .help {
          height: 100%;
          max-height: 600px;
        }

        .chat {
          height: 100%;
          max-height: 600px;
        }

        .intro {
          width: 100%;
          max-width: 360px;
        }
      }
    }
  }

  // end of mobile app
}

//screens bigget than 1024px

@media screen and (min-width: 1024px) {
  .container {
    max-width: 1200px;
  }

  //no styles for the header

  //hero
  .hero {
    padding-bottom: 100px;

    .container {
      .promo-images {
        height: auto;

        .promo-big-image,
        .promo-small-image {
          height: 500px;
          width: auto;
        }
      }
    }
  }

  // end of hero

  //chat
  //end of chat

  .chat {
    position: unset;

    .container {
      position: relative;

      .chat-images {
        .char-image-potrait {
          left: 150px;
          top: 150px;
          bottom: unset;
          width: 200px;
        }

        .char-image-desktop {
          right: 50px;
          max-width: 600px;
        }
      }
    }
  }

  //no styles for product discovery on this breakpoint
  //no styles for fee structure on this breakpoint

  //subscription
  .subscription {
    padding: 100px 0;
  }

  //end of subscription

  //empower vendors
  .empower-vendors {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
    }
  }

  //end of empower vendors

  // buyer experience
  .buyer-experience {
    .container {
      flex-direction: row;
      align-items: start;
      justify-content: space-between;
      gap: 24px;

      .col-left {
        width: 40%;
        height: 300px;
        position: relative;

        .image-buyer {
          position: absolute;
          max-width: 570px;
          height: auto;
          left: 0;
          right: 0;
          top: 50px;
        }
      }

      .col-right {
        width: 60%;
        text-align: end;
        display: grid;
        justify-items: end;

        p {
          width: 50%;
        }
      }
    }
  }

  // end of buyer experience

  //mobile app
  //end of mobile app

  .mobile-app {
    height: auto;
    padding-bottom: 60px;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;

      .col-right {
        height: auto;
        width: 459px;
        position: relative;
        right: unset;

        .intro {
          width: 220px;
        }

        .help,
        .chat {
          height: 361px;
        }
      }
    }
  }
}

.pop-up {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.433);
  z-index: 101;

  display: none;
  align-items: center;
  justify-content: center;

  .pop-up-content {
    position: relative;
    max-width: 500px;
    height: fit-content;
    background-color: $CShade;
    padding: 48px;
    display: grid;
    gap: 24px;
    border-radius: 12px;

    .pop-up-actions {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 24px;
      top: 24px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $CShade;
      cursor: pointer;
    }

    .notification-form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px;

      input {
        padding: 12px 20px;
        width: 100%;
      }

      .submit-button {
        display: flex;
        align-items: center;
        gap: 6px;

        input {
          padding: 0;
          background-color: transparent;
          color: inherit;
          outline: none;
          border: none;
        }
      }

      .submit-section {
        display: flex;
        align-items: center;
        gap: 12px;

        .submit-button {
          cursor: pointer;
        }

        #loading-spinner {
          display: none;
          font-size: 28px;
          color: $CPrimary;
          animation: spin 2s linear infinite;
        }

        .submited {
          color: $CPrimary;
          display: none;
        }

        .failed {
          color: red;
          display: none;
        }
      }

      .half {
        width: 48%;
      }
    }
  }
}

//pop up mobile

@media screen and (max-width: 540px) {
  .pop-up {
    padding: 24px;

    .pop-up-content {
      .notification-form {
        flex-wrap: nowrap;
        flex-direction: column;

        .half {
          min-width: 100%;
        }

        .submit-section {
          display: grid;
          gap: 12px;
        }
      }
    }
  }
}

//Loading spinner animation

@keyframes spin {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}

.footer {
  background-color: $CPrimary;
  padding: 58px 50px;
  color: white;

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;

    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .col {
        h2 {
          color: white;
        }

        display: flex;
        flex-direction: column;
        gap: 24px;

        ul {
          display: flex;
          flex-direction: column;
          gap: 12px;
          list-style: none;

          li {
            a {
              color: white;
              text-decoration: none;
            }
          }
        }
      }
    }

    .copyrights {
      display: flex;
      width: 100%;
      padding-top: 24px;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #fff;

      .socialMedia {
        display: flex;
        align-items: flex-start;
        gap: 17px;

        i {
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .footer {
    padding: 20px 12px;

    .container {
      .row {
        display: grid;
        grid-template-columns: 48% 48%;

        .col {
          width: 100%;
          gap: 12px;
          padding-bottom: 24px;
        }
      }

      .copyrights {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .footer {
    padding: 20px 12px;

    .container {
      .row {
        display: flex;
        flex-direction: column;

        .col {
          display: flex;
          gap: 12px;
          padding-bottom: 24px;
        }
      }

      .copyrights {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
      }
    }
  }
}

.menu-open {
  height: 100vh;
  visibility: visible;
  overflow-y: clip;
  display: grid;
  justify-items: center;
  gap: 24px;
  position: absolute;
  right: 0;
  left: 0;
  background-color: $CShade;
  top: 90px;
  bottom: 0;

  padding: 53px 24px;
}