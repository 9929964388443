@import './variables/variables.scss';

h1,
h2,
h3 {
    color: $primary-color
}

.container {
    max-width: 1200px;
    margin: auto;
    padding: 0;
}

.title {
    font-size: 50px;
    color: $primary-color;
    line-height: 50px;
}

.loading-icon {
    display: flex;
    align-items: center;
    gap: 6px;
    animation: loadingIcon 0.9s infinite linear;
}

@keyframes loadingIcon {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.language-switcher {
    background-color: $accent-color;
    position: fixed;
    bottom: 24px;
    right: 0;
    z-index: 100;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 12px solid $accent-color;
    gap: 12px;
    color: white;
    font-size: 18px;
    width: 100px;
    transition: all ease-in-out 0.3s;
    cursor: pointer;

    .language {
        display: none;
        align-items: center;
        gap: 12px;
        background-color: white;
        color: $primary-color;
        padding: 6px;
        border-radius: $border-radius;

        .flag {
            width: 22px;
            height: 22px;
            border-radius: $border-radius;
        }


    }

    .active {
        display: flex;

    }

    &:hover {
        width: 180px;

        .language {
            display: flex;
        }
    }
}

button,
.button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
    padding: 14px 25px;
    border-radius: $border-radius;
    transition: ease-in-out 0.3s;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

}

.primary-button {
    background-color: $primary-color;
    color: white;

    &:hover {
        background-color: transparent;
        color: $primary-color;
        border-radius: 0;
        border: 1px solid $primary-color;
    }
}

.outline-button {
    background-color: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;
}

.form-link {
    color: $primary-color;
    text-decoration: underline;
    cursor: pointer;
}

.form,
.business-info {
    display: grid;
    height: fit-content;
    gap: 12px;

    .account-type {
        border: 1px solid $primary-color;
        padding: 12px;
        border-radius: $border-radius;
        display: grid;
        height: fit-content;
        gap: 12px;
    }

    .type {
        background-color: $accent-color;
        padding: 14px 25px;
        border-radius: $border-radius;

    }

    .selected {
        background-color: $primary-color;
        color: white;
    }

    .filed-with-label {
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            width: 33%;
        }
    }

    button {
        min-width: 40%;
    }

}

input,
textarea,
select {
    width: 100%;
    padding: 14px 25px;
    border-radius: $border-radius;
    outline: none;
    border: 1px solid $secondary_color;
    background-color: transparent;

    &:focus {
        background-color: transparent;
    }
}

.password-input {
    display: flex;
    align-items: stretch;
    gap: 12px;

    .icon {
        background-color: $primary-color;
        align-items: center;
        justify-content: center;
        color: white;
        width: 53px;
        border-radius: $border-radius;
        position: relative;

        i {
            position: absolute;
            top: 30%;
            left: 30%;
        }
    }
}

.half {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 12px;
}


.popup {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: center;

    .popup-content {
        max-width: 500px;
        margin: auto;
        background-color: white;
        padding: 24px;
        border-radius: $border-radius;
        box-shadow: 4px 4px 90px 2px rgba(20, 100, 84, 0.45);
        padding-top: 48px;
        position: relative;
        transition: all ease-in-out 0.3s;

        .content {
            display: grid;
            gap: 12px;
        }

        .close-popup {
            cursor: pointer;
            position: absolute;
            top: 24px;
            right: 24px;

            i {
                font-size: 28px;
                color: $primary-color;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .container {
        padding: 0 24px;
    }
}

@media screen and (max-width: 754px) {
    .container {
        max-width: 540px;
    }

    .form {
        .filed-with-label {
            input {
                width: 170px;
            }
        }
    }

    .title {
        font-size: 32px;
    }

    .buttons {
        flex-direction: column;
    }
}

@media screen and (max-width: 540px) {
    .popup {
        padding: 12px;
    }
}