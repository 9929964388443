h1,
h2,
h3 {
  color: #146454;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0;
}

.title {
  font-size: 50px;
  color: #146454;
  line-height: 50px;
}

.loading-icon {
  display: flex;
  align-items: center;
  gap: 6px;
  animation: loadingIcon 0.9s infinite linear;
}

@keyframes loadingIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.language-switcher {
  background-color: #D4CCA4;
  position: fixed;
  bottom: 24px;
  right: 0;
  z-index: 100;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 12px solid #D4CCA4;
  gap: 12px;
  color: white;
  font-size: 18px;
  width: 100px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.language-switcher .language {
  display: none;
  align-items: center;
  gap: 12px;
  background-color: white;
  color: #146454;
  padding: 6px;
  border-radius: 6px;
}
.language-switcher .language .flag {
  width: 22px;
  height: 22px;
  border-radius: 6px;
}
.language-switcher .active {
  display: flex;
}
.language-switcher:hover {
  width: 180px;
}
.language-switcher:hover .language {
  display: flex;
}

button,
.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  padding: 14px 25px;
  border-radius: 6px;
  transition: ease-in-out 0.3s;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.primary-button {
  background-color: #146454;
  color: white;
}
.primary-button:hover {
  background-color: transparent;
  color: #146454;
  border-radius: 0;
  border: 1px solid #146454;
}

.outline-button {
  background-color: transparent;
  border: 1px solid #146454;
  color: #146454;
}

.form-link {
  color: #146454;
  text-decoration: underline;
  cursor: pointer;
}

.form,
.business-info {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
}
.form .account-type,
.business-info .account-type {
  border: 1px solid #146454;
  padding: 12px;
  border-radius: 6px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
}
.form .type,
.business-info .type {
  background-color: #D4CCA4;
  padding: 14px 25px;
  border-radius: 6px;
}
.form .selected,
.business-info .selected {
  background-color: #146454;
  color: white;
}
.form .filed-with-label,
.business-info .filed-with-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form .filed-with-label input,
.business-info .filed-with-label input {
  width: 33%;
}
.form button,
.business-info button {
  min-width: 40%;
}

input,
textarea,
select {
  width: 100%;
  padding: 14px 25px;
  border-radius: 6px;
  outline: none;
  border: 1px solid #297549;
  background-color: transparent;
}
input:focus,
textarea:focus,
select:focus {
  background-color: transparent;
}

.password-input {
  display: flex;
  align-items: stretch;
  gap: 12px;
}
.password-input .icon {
  background-color: #146454;
  align-items: center;
  justify-content: center;
  color: white;
  width: 53px;
  border-radius: 6px;
  position: relative;
}
.password-input .icon i {
  position: absolute;
  top: 30%;
  left: 30%;
}

.half {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 12px;
}

.popup {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup .popup-content {
  max-width: 500px;
  margin: auto;
  background-color: white;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 4px 4px 90px 2px rgba(20, 100, 84, 0.45);
  padding-top: 48px;
  position: relative;
  transition: all ease-in-out 0.3s;
}
.popup .popup-content .content {
  display: grid;
  gap: 12px;
}
.popup .popup-content .close-popup {
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
}
.popup .popup-content .close-popup i {
  font-size: 28px;
  color: #146454;
}

@media screen and (max-width: 1280px) {
  .container {
    padding: 0 24px;
  }
}
@media screen and (max-width: 754px) {
  .container {
    max-width: 540px;
  }
  .form .filed-with-label input {
    width: 170px;
  }
  .title {
    font-size: 32px;
  }
  .buttons {
    flex-direction: column;
  }
}
@media screen and (max-width: 540px) {
  .popup {
    padding: 12px;
  }
}/*# sourceMappingURL=main.css.map */